.article-card{
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-bottom: 1rem;
}

label{
    margin-bottom: 0!important;
}

.row-selector input[type="checkbox"]{
    cursor:pointer;
}

.tabla-art tbody tr td{
    padding:10px!important;
}

.chk-selector{
    cursor: pointer;
}

.circle-avatar {
    width: 40px;
    height: 40px;
    background-color: #007bff; /* Color de fondo del círculo */
    color: #fff; /* Color del texto (iniciales) */
    border-radius: 50%; /* Borde redondeado para formar un círculo */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
  }

  .avatar-container {
    width: 38px; /* Ajusta al tamaño deseado */
    height: 38px; /* Ajusta al tamaño deseado */
    border-radius: 50%; /* Esto hará que el contenedor sea circular */
    /* border: 2px solid rgb(178, 177, 179);  */
    display: flex;
    justify-content: center;
    align-items: center;
}

.avatar-container img {
    width: 34px; /* Ajusta al tamaño deseado, debe ser menor que el contenedor para ver el borde */
    height: 34px; /* Ajusta al tamaño deseado, debe ser menor que el contenedor para ver el borde */
    border-radius: 50%; /* Asegura que la imagen también sea circular */
}

.swal2-container {
    z-index: 1100 !important; /* Asegúrate de que sea más alto que el z-index de otros modales */
  }
  
.swal-custom {
    z-index: 2000 !important; 
  }

  .spinner-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1; /* Asegura que el spinner quede por encima del contenido */
  }

  .select-remito{
    margin: 0 0 10px 0;
    width: 50%; /* Opcional, puedes ajustar el ancho */
    text-align: center;
  }
  .popup-centrado {
    display: flex!important;
    flex-direction: column!important;
    align-items: center!important; /* Centra horizontalmente el contenido */
  }