.requests-table table tbody tr td{
    padding: 5px!important;

}
.requests-table table thead tr th{
    padding: 10px!important;
    font-weight:normal!important;
}

td[rowspan] {
    vertical-align: middle;
  }
  