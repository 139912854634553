.overlay {
    position: fixed;
    bottom: 0;
    left: 20px;
    width: 350px;
    height: 500px;
    background-color: white;
    z-index: 1000;
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
    box-shadow: -5px -5px 15px rgba(0, 0, 0, 0.2);
  }
  
  .overlay.open {
    transform: translateY(0);
  }
  
  .menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    background-color: #f2f2f2;
    border-bottom: 1px solid #ccc;
  }
  
  .close-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
  }
  