
.lista-pedidos {
        display: inline-block;
        vertical-align: top;
        margin-right: 20px;
        margin-bottom: 20px;
    }
    
    .lista-pedidos ul {
        list-style-type: none;
        padding-left: 0;
        columns: 4;
    }
    
    .lista-pedidos ul li {
        margin-bottom: 5px;
    }
    .lista-pedidos ul li:before {
        content: "•";
        margin-right: 5px;
        color: darkgray;
    }

    .lista-pedidos p{
        font-size: 1.1em;
    }