.notification-icon {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    width:50px;
}

.notification-icon i {
    margin-right: 5px; /* Espacio entre el icono y el número de notificaciones */
    font-size: 2rem;
}

.notification-icon .badge {
    position: relative;
    animation: notificationAnimation 0.5s ease-in-out;
}

.notification-animation i {
    /* animation: pulseAnimation 0.5s infinite; */
    animation-name: pulseAnimation;
    animation-duration: 2s;
    animation-iteration-count: 3;
    animation-timing-function: ease;
}

@keyframes pulseAnimation {
    0% {
      -webkit-transform: rotateZ(0);
  }
  1% {
      -webkit-transform: rotateZ(30deg);
  }
  3% {
      -webkit-transform: rotateZ(-28deg);
  }
  5% {
      -webkit-transform: rotateZ(34deg);
  }
  7% {
      -webkit-transform: rotateZ(-32deg);
  }
  9% {
      -webkit-transform: rotateZ(30deg);
  }
  11% {
      -webkit-transform: rotateZ(-28deg);
  }
  13% {
      -webkit-transform: rotateZ(26deg);
  }
  15% {
      -webkit-transform: rotateZ(-24deg);
  }
  17% {
      -webkit-transform: rotateZ(22deg);
  }
  19% {
      -webkit-transform: rotateZ(-20deg);
  }
  21% {
      -webkit-transform: rotateZ(18deg);
  }
  23% {
      -webkit-transform: rotateZ(-16deg);
  }
  25% {
      -webkit-transform: rotateZ(14deg);
  }
  27% {
      -webkit-transform: rotateZ(-12deg);
  }
  29% {
      -webkit-transform: rotateZ(10deg);
  }
  31% {
      -webkit-transform: rotateZ(-8deg);
  }
  33% {
      -webkit-transform: rotateZ(6deg);
  }
  35% {
      -webkit-transform: rotateZ(-4deg);
  }
  37% {
      -webkit-transform: rotateZ(2deg);
  }
  39% {
      -webkit-transform: rotateZ(-1deg);
  }
  41% {
      -webkit-transform: rotateZ(1deg);
  }
  
  43% {
      -webkit-transform: rotateZ(0);
  }
  100% {
      -webkit-transform: rotateZ(0);
  }
}
    