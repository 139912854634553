.article-card{
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-bottom: 1rem;
}

label{
    margin-bottom: 0!important;
}

.row-selector input[type="checkbox"]{
    cursor:pointer;
}

.tabla-art tbody tr td{
    padding:10px!important;
}

.chk-selector{
    cursor: pointer;
}