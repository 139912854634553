.noShadow {
    box-shadow: 'none';
    background-color: white;
}

.form-wrapper {
    position: relative;
    padding:20px;
  }
  
  .form-wrapper.disabled::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 1em;
    background-color: rgba(0, 0, 0, 0.02);
    pointer-events: all;
    
  }
  
  .form-wrapper.disabled::before {
    /* ... (resto del CSS) ... */
    pointer-events: all;
  }
  
  .form-wrapper.disabled .MuiTabs-root {
    pointer-events: none;
  }


  .form-wrapper.disabled  {
    pointer-events: none;
  }