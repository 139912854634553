div.noti textarea.noti__input:focus{
    /* color: green!important;
    border-color: red!important; */
    /* border-color:red!important */
}

.noti__control{

  }
  .noti__input{
    background-color: #fff;
    border-radius: 3px;
    transition: 0.3s ease-in-out;
    padding: 9px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    border: 1px solid #dedede;
    &:focus,&:active{
      outline: none;
      border: 1px solid #3fb9de;
    }
    &::placeholder {
      color: #a4b0be;
      font-size: 14px;
    }
  }

