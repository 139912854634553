.Modal
  position: fixed
  z-index: 999
  top: 0
  right: 0
  bottom: 0
  left: 0
  background-color: rgba(0, 0, 0, 0.5)
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  .Modal-content
    background-color: #fff
    border-radius: 8px
    box-sizing: border-box

  .Modal-content.card
    width: 90%

    .Modal-body
      padding: 16px

      .table-wrapper
        max-height: 360px
        overflow-y: auto