
.camion{
    position: absolute;
    top: 50px; /* Coloca el GIF en el centro verticalmente */
    transform: translateX(-100px);
    opacity:0.5;
    animation: slideIn 1s forwards;
    width: 150px;
    height: auto;
    /* animation-iteration-count: 6; */
}

@keyframes slideIn {
    from {
      left: 0px;
    }
    to {
      left: 100px;
    }
  }