.modal-loading
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba(230, 230, 230, 0.7)
  z-index: 9999


  &-content
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    font-size: 50px // Cambia esto para ajustar el tamaño del ícono
    color: white // Color del ícono

    & p
      text-align: center
      font-size: 20px

    & img
      width: 250px