

  .rack {
    width: 200px;
    height: 100px;
    margin: 10px;
    cursor:pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Para alinear horizontalmente si es necesario */
    text-align: center;
  }

  .ambiente{    
    background-color: #ece365;
  }
  .frio{    
    background-color: #05e5f5;
  }
  .shelf {
    width: 200px;
    height: 100px;
    background-color: #f5b4e1;
    margin: 10px;
  }
