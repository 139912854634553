.budget-actions
  list-style: none
  margin: 0
  padding: 10px 0

  &--item
    display: flex
    flex-direction: row
    align-items: center
    padding: 4px 6px
    border-radius: 4px
    cursor: pointer

    &:hover
      color: var(--bs-white)
      background-color: var(--bs-primary)

      .budget-actions--item-icon
        color: var(--bs-white)

    &--icon
      color: var(--bs-primary)

    & p
      margin: 0
      margin-left: 6px