.gps_ring {
    border: 3px solid #999;

    -webkit-border-radius: 32px;
    border-radius: 32px;

    height: 20px;
    width: 20px;

    -webkit-animation: pulsate 1s ease-out;
    -webkit-animation-iteration-count: 33; 

    animation: pulsate 1s ease-out;
    animation-iteration-count: 33;

    opacity: 0.0
}

/* webkit - safari, chrome */
@-webkit-keyframes pulsate {
    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}
/* no vendor prefix - firefox */
@keyframes pulsate {
    0% { transform: scale(0.1, 0.1); opacity: 0.0; }
    50% { opacity: 1.0; }
    100% { transform: scale(1.2, 1.2); opacity: 0.0; }
}