.image-container {
  position: relative;
  display: inline-block;
  overflow: hidden; /* Asegura que el zoom no se salga del contenedor */
}

.custom-image {
  width: 100%;
  height: auto;
  border-radius: 10px 10px 0 0;
  max-width: 300px;
  max-height: 300px;
  object-fit: cover;
  transition: transform 0.3s ease, opacity 0.3s ease; /* Transiciones suaves */
}

.image-container:hover .custom-image {
  transform: scale(1.1); /* Zoom al hacer hover */
  opacity: 0.7; /* Reducir la opacidad al hacer hover */
}

.hover-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1.2rem;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semi-transparente */
  padding: 10px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.3s ease; /* Transición suave */
  text-align: center;
}

.image-container:hover .hover-text {
  opacity: 1; /* Mostrar el texto al hacer hover */
}
