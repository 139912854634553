/* Ajusta si quieres separar el timeline del borde del modal */
.timeline-container {
  margin-left: 1rem;
}

/* Cada ítem del timeline */
.timeline-item {
  position: relative;
  padding: 20px 0; /* espacio vertical para el segmento de línea y el círculo */
}

/* Línea “segmentada” (arriba/abajo) */
.timeline-item::before,
.timeline-item::after {
  content: "";
  position: absolute;
  /* Aquí decides dónde estará el “centro” de la línea */
  left: 17px;
  width: 2px;
  background-color: #007bff;
}

/* Tramo de línea superior (faltando en el primer ítem) */
.timeline-item:not(:first-child)::before {
  top: 0;
  bottom: 50%;
}

/* Tramo de línea inferior (faltando en el último ítem) */
.timeline-item:not(:last-child)::after {
  top: 50%;
  bottom: 0;
}

/* El círculo (punto) */
.timeline-icon {
  position: absolute;
  /*
    Haz coincidir su centro con left: 18px, que está justo
    en la mitad del “ancho” de la línea (2px): 17..19px
  */
  left: 18px;
  top: 50%;
  transform: translate(-50%, -50%);

  /* Tamaño total (incluyendo el borde) */
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid #007bff;
  background-color: #fff;
  z-index: 1; /* Por encima de la línea */
}

/* Estilo de la card */
.timeline-card {
  border: none;
  box-shadow: 0 2px 6px rgba(0,0,0,0.08);
  border-radius: 8px;
  margin-left: 3rem; /* separas la card de la columna del timeline */
}