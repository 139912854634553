


.dropdown-context {
    position: relative;
  }
  
.dropdown-context-menu {
    border: 1px solid #cdcdcd;
    position: absolute;
    left: 0;
    top: calc(100% + .25rem);
    background-color: white;
    padding: .75rem;
    border-radius: .25rem;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .1);
    opacity: 0;
    pointer-events: none;
    transform: translateY(-10px);
    /* transition: opacity 500ms ease-in-out, transform 150ms ease-in-out; */
    transition: opacity linear 0.3s, transform cubic-bezier(0.5, 0, 0.5, 1) 0.3s;
}

.dropdown-context.active > .dropdown-context-menu {
    opacity: 1;
    /* transform: translateY(0); */
    pointer-events: auto;
    /* animation: fade_in_1 500ms ease-out; */
}

.information-grid {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    padding-top: 10px;
}
  
.dropdown-links {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    cursor: pointer;
}

.dropdown-context-links > span{
    display: block;
    width: 200px;
    padding: 8px;
}

@keyframes fade_in_1 {
    0%, 14% {
        opacity: 0;
        transform: translateY(1rem) scale(0.95);
    }
    15% {
        opacity: 0.25;
    }
    30%, 100% {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
}

